import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useLocation } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import * as Api from 'src/api'
import { CourseChosenIcon } from 'src/assets/icons/customIcons/course-icons/CourseChosen'
import { CourseCompletedIcon } from 'src/assets/icons/customIcons/course-icons/CourseCompleted'
import { CourseFailedIcon } from 'src/assets/icons/customIcons/course-icons/CourseFailed'
import { SelectedCoursesIcon } from 'src/assets/icons/customIcons/page-icons/SelectedCourses'
import { shortenString } from 'src/helpers/fns'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { useUserState } from 'src/hooks/userState'
import Warning from 'src/imgs/classroom_icon.svg'
import { useTheme } from 'src/state/providers/Theme'
import { Card } from 'src/tailwind/components/Card'
import { Section } from 'src/tailwind/components/Section'
import * as Table from 'src/tailwind/components/Table'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Loader from 'src/views/components/Loader'
import NoContent from '../../../../components/NoContent'

export default function StudentChoicesPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('course:selected_courses')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}
function PageContent(): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const { data } = useApi({
    endpoint: Api.getStudentChoices,
    params: React.useMemo(() => ({ headers }), [headers]),
  })
  const choices = data
  const t = useTranslatable()
  const locale = useLocale()
  const userState = useUserState()
  const { pathname } = useLocation()
  const theme = useTheme()
  if (userState.data == null) return null

  const breadcrumbsItems = [{ page: `${t('course:selected_courses')}`, path: pathname }]

  return (
    <div>
      <Section title={t('course:selected_courses')} icon={<SelectedCoursesIcon />} breadcrubms={breadcrumbsItems}>
        {choices != null && choices.length > 0 ? (
          <>
            <div className="mt-3 flex flex-wrap items-center justify-start gap-3 p-3 text-xs text-primaryTextColor">
              <div className="flex items-center justify-center gap-1">
                <CourseCompletedIcon />
                <span>{t('course:successfully_finished')}</span>
              </div>
              <div className="flex items-center justify-center gap-1">
                <CourseChosenIcon />
                <span>{t('course:chosen_in_current_semester')}</span>
              </div>
              <div className="flex items-center justify-center gap-1">
                <CourseFailedIcon />
                <span>{t('course:failed')}</span>
              </div>
            </div>
            <Table.Table>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th />
                  <Table.Th>{t('common:code')}</Table.Th>
                  <Table.Th className="min-w-[300px]">{t('course:course_name')}</Table.Th>
                  <Table.Th className="!text-center">{t('common:score')}</Table.Th>
                  <Table.Th className="!text-center">{t('common:gain_credits')}</Table.Th>
                  <Table.Th className="!text-center">{t('common:credits')}</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {choices.map((choice) => (
                  <Table.Tr key={choice.id} data-testid={`choice/${choice.id}`}>
                    <Table.Td>
                      {choice.status === 'FAILED' && (
                        <div data-tooltip-id={`${choice.id}-statusTooltip`} data-testid="failedIcon">
                          <CourseFailedIcon />
                        </div>
                      )}
                      {choice.status === 'PASSED' ? (
                        <div data-tooltip-id={`${choice.id}-statusTooltip`} data-testid="passedIcon">
                          <CourseCompletedIcon />{' '}
                        </div>
                      ) : null}
                      {choice.status === 'CURRENT' && (
                        <div data-tooltip-id={`${choice.id}-statusTooltip`} data-testid="current">
                          <CourseChosenIcon />
                        </div>
                      )}
                      <Tooltip
                        id={`${choice.id}-statusTooltip`}
                        place="top"
                        variant={theme === 'dark' ? 'dark' : 'light'}
                        className="mb-1 p-1"
                      >
                        <p className="mb-0">
                          {choice.status === 'PASSED' && t('course:successfully_finished')}
                          {choice.status === 'CURRENT' && t('course:chosen_in_current_semester')}
                          {choice.status === 'FAILED' && t('course:failed')}
                        </p>
                      </Tooltip>
                    </Table.Td>
                    <Table.Td
                      className="whitespace-nowrap align-middle text-sm text-primaryTextColor"
                      title={choice.courseCode}
                      data-testid="code"
                    >
                      {shortenString(choice.courseCode, 50)}
                    </Table.Td>
                    <Table.Td className="min-w-[300px] text-primaryBlueLink dark:text-primaryTextColor">
                      <Link
                        className="hover:underline"
                        to={`/${locale}/student/courses/${choice.course!.id}/groups`}
                        data-testid="name"
                      >
                        {choice.courseName}
                      </Link>
                    </Table.Td>
                    <Table.Td className="text-center text-primaryTextColor" data-testid="score">
                      {choice.isLatChoice ? choice.latScore : choice.score.toFixed(2)}
                    </Table.Td>
                    <Table.Td className="text-center text-primaryTextColor" data-testid="credits">
                      {choice.isLatChoice && choice.courseCredits === 0 ? '' : choice.credits}
                    </Table.Td>
                    <Table.Td className="text-center text-primaryTextColor" data-testid="courseCredits">
                      {choice.courseCredits}
                    </Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table.Table>
            <Card className="mt-4 flex h-[60px] items-center justify-end pr-[60px] text-primaryTextColor sm:pr-3 xxs:pr-3 xs:pr-3">
              <div className="flex">
                {t('course:total_chosen_courses_credits_sum')}:
                <span data-testid="choices/creditsSum" className="ml-1">
                  {choices.reduce((sum, choice) => choice.courseCredits + sum, 0)}
                </span>
              </div>
            </Card>
          </>
        ) : (
          <NoContent
            header={t('error:courses_not_found')}
            image={Warning}
            subHeader={t('course:you_have_no_selected_courses_in_current_semester')}
          />
        )}
      </Section>
    </div>
  )
}
