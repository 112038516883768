import classNames from 'classnames'

interface Props {
  readonly title: JSX.Element | string
  readonly content?: JSX.Element
}

export default function SummaryRow({ title, content }: Props): JSX.Element {
  return (
    <div className="my-1 flex w-full flex-col items-baseline justify-center md:flex-row">
      <h4
        className={classNames('mr-4 w-full text-left font-bold md:w-1/5 md:text-right', {
          'text-sm': content != null,
        })}
      >
        {title}
      </h4>
      <div className="flex w-full flex-col text-primaryTextColor md:w-4/5">
        {content ?? <div className="h-[2px] w-full bg-primaryBlue" />}
      </div>
    </div>
  )
}
