import * as FreeSolidSvgIcons from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'
import * as React from 'react'
import * as Api from 'src/api'
import Argus from 'src/assets/icons/customIcons/Argus'
import { formatDatelocale } from 'src/helpers/fns'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useAuth } from 'src/hooks/auth/auth'
import { useDateLocale, useTranslatable } from 'src/hooks/locale/utils'
import UserPhoto from 'src/views/components/UserPhoto'
import SummaryRow from './components/SummaryRow'

interface Props {
  readonly show: boolean
}

export default function Personal({ show }: Props): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const auth = useAuth()
  const t = useTranslatable()
  const dateLocale = useDateLocale()

  const { data: personal } = useApi({
    endpoint: Api.getStudentResumePersonal,
    params: React.useMemo(() => ({ headers }), [headers]),
  })
  if (auth.state !== 'Authenticated') return null

  return (
    <div className="flex w-full flex-col justify-between">
      <div className="mb-5 flex w-full flex-wrap items-start justify-between p-5">
        <div className="w-[96px]">
          <Argus />
        </div>
        <h3 className="hidden md:block">Curriculum Vitae</h3>
        <div className="my-2 h-[120px] w-[96px] cursor-pointer overflow-hidden">
          {personal.resumePhoto?.url != null ? (
            <img src={personal.resumePhoto.url} className="h-[120px] w-full max-w-full bg-white object-contain" />
          ) : (
            <UserPhoto
              height="100%"
              width="100%"
              user={auth.user}
              className="h-auto max-w-full !rounded-none object-cover"
            />
          )}
        </div>
      </div>
      {show && (
        <SummaryRow
          title={t('person:personal_information')}
          content={
            <>
              <p>{auth.user.fullName}</p>
              {personal.address.length > 0 && (
                <div className="mt-1 flex items-center">
                  <FontAwesomeIcon icon={FreeSolidSvgIcons.faLocationDot} />
                  <span className="ml-2">{personal.address}</span>
                </div>
              )}
              <div className="flex w-fit items-center justify-between">
                {personal.homePhone.length > 0 && (
                  <div className="mr-2 mt-1 flex items-center">
                    <FontAwesomeIcon icon={FreeSolidSvgIcons.faPhone} />
                    <span className="ml-2">{personal.homePhone}</span>
                  </div>
                )}
                {personal.mobilePhone.length > 0 && (
                  <div className="mt-1 flex items-center">
                    <FontAwesomeIcon icon={FreeSolidSvgIcons.faMobileScreenButton} />
                    <span className="ml-2">{personal.mobilePhone}</span>
                  </div>
                )}
              </div>
              <div className="mt-1 flex items-center">
                <FontAwesomeIcon icon={FreeSolidSvgIcons.faEnvelope} />
                <span className="ml-2">{auth.user.email}</span>
              </div>
              <div className="mt-1 flex items-center">
                <FontAwesomeIcon icon={FreeSolidSvgIcons.faMarsAndVenus} />
                <span className="ml-2">{auth.user.gender === 'female' ? t('person:female') : t('person:male')}</span>
              </div>

              <div className="mt-1 flex items-center">
                <FontAwesomeIcon icon={FreeSolidSvgIcons.faCalendar} />
                <span className="ml-2">
                  {format(formatDatelocale(auth.user.birthDate) as Date, 'd MMMM yyyy', { locale: dateLocale })}{' '}
                </span>
              </div>
            </>
          }
        />
      )}
    </div>
  )
}
