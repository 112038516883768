import { useFormikContext, type FormikHelpers } from 'formik'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useMap } from 'react-use'
import * as Api from 'src/api'
import { UploadConfigMap, type StatementTemplate, type StatementTemplateView } from 'src/api'
import { InfoIcon } from 'src/assets/icons/customIcons/Info'
import Statements from 'src/assets/icons/customIcons/page-icons/Statements'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { type FileState } from 'src/hooks/fileUpload'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { Section } from 'src/tailwind/components/Section'
import BackButton from 'src/views/components/BackButton'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Loader from 'src/views/components/Loader'
import FileInput from 'src/views/components/forms/formik/FileInput'
import { Form } from 'src/views/components/forms/formik/Form'
import { FormError } from 'src/views/components/forms/formik/FormError'
import { FormSubmit } from 'src/views/components/forms/formik/FormSubmit'
import SelectInput from 'src/views/components/forms/formik/SelectInput'
import TextInput from 'src/views/components/forms/formik/TextInput'
import Swal from 'sweetalert2'

interface FormikValues {
  readonly templateID: string
  readonly 'template[text]': string
  readonly phone: string
  readonly address: string
  readonly 'template[year]': string
  readonly 'template[year2]': string
  readonly 'template[semester]': string
  readonly 'template[semester2]': string
  readonly 'template[place]': string
  readonly files: readonly FileState[]
  readonly template: {
    programId: string
    facultyId: string
    semesterId: string
  }
}

export default function StudentStatementsAddPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('statement:add_statement')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const [currentTemplateId, setCurrentTemplateId] = React.useState<string>()
  const headers = useAuthenticatedHeaders()
  const [successfullySubmited, setSuccessfullySubmited] = React.useState(false)
  const templates = useApi({
    endpoint: Api.getStudentStatementsTemplates,
    params: React.useMemo(
      () => ({
        headers,
      }),
      [headers]
    ),
  })

  const currentTemplate = useApi({
    endpoint: Api.getStudentStatementTemplate,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: currentTemplateId!,
        },
      }),
      [headers, currentTemplateId]
    ),
    shouldFetch: currentTemplateId != null,
  })

  const t = useTranslatable()
  const formRef = React.useRef<HTMLFormElement>(null)
  const locale = useLocale()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (successfullySubmited) {
      navigate(`/${locale}/student/statements`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successfullySubmited])

  async function onSubmit(values: FormikValues, formikHelpers: FormikHelpers<FormikValues>): Promise<void> {
    const { files } = values
    void (async () => {
      if (formRef.current == null) return
      try {
        if (!formRef.current.checkValidity()) {
          formRef.current.reportValidity()
        } else {
          const alert = await Swal.fire({
            title: t('statement:do_you_really_want_to_send_this_statement'),
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#0D6EFD',
            cancelButtonColor: '#6C757D',
            confirmButtonText: t('common:confirm'),
            cancelButtonText: t('common:cancel'),
          })

          if (alert.isConfirmed && formRef.current != null) {
            const data: { [key: string]: string } = {}

            for (const [key, value] of new FormData(formRef.current).entries()) {
              if (typeof value === 'string') {
                data[key] = value
              }
            }
            const facultyId =
              (currentTemplate.data?.isTypeMobility ?? false)
                ? currentTemplateId === '9'
                  ? values.template.facultyId
                  : currentTemplate.data?.studentFacultyId.toString()
                : undefined

            await Api.postStudentStatements({
              headers,
              body: {
                template: {
                  id: currentTemplateId,
                  text: data['template[text]'],
                  phone: data.phone,
                  address: data.address,
                  year: data['template[year]'],
                  year2: data['template[year2]'],
                  semesterId: values.template.semesterId,
                  semester: data['template[semester]'],
                  semester2: data['template[semester2]'],
                  facultyId,
                  programId: values.template.programId,
                  place: data['template[place]'],
                },
                files: files.flatMap((e) => e.remoteFile?.id ?? []),
              },
            })

            toast.success(t('statement:statement_sent'))
            setSuccessfullySubmited(true)
          }
        }
      } catch (error: any) {
        toast.error(t('error:an_error_occurred'))

        const errors = document.querySelectorAll('.template-error-message')
        if (errors.length > 0) {
          errors.forEach((e) => {
            e.remove()
          })
        }
        const errEntries: Array<[string, [string]]> = Object.entries(error.errors) ?? []

        if (errEntries.length > 0 && formRef.current != null) {
          errEntries?.forEach(([key, value]) => {
            const name = key.split('.')[1]
            const input = formRef.current!.querySelector(`[name="template[${name}]"]`)
            if (name === 'phone' || name === 'address') {
              formikHelpers.setFieldError(name, value[0])
            }
            if (input != null) {
              const wrapper = document.createElement('div')
              wrapper.classList.add('template-input-wrapper')
              input.parentNode?.insertBefore(wrapper, input)
              wrapper.appendChild(input)
              input.classList.add('error')
              const error = document.createElement('span')
              error.classList.add('template-error-message')
              error.innerHTML = value.map((e) => e).join('.')
              input.after(error)
            }
          })
        }
      }
    })()
  }

  const initialValues: FormikValues = {
    templateID: currentTemplateId ?? '',
    'template[year]': '',
    'template[year2]': '',
    'template[semester]': '',
    'template[semester2]': '',
    'template[place]': '',
    'template[text]': '',
    phone: '',
    address: '',
    files: [],
    template: {
      programId: '',
      facultyId: '',
      semesterId: '',
    },
  }
  const breadcrumbsItems = [
    { page: `${t('statement:statements')}`, path: `/${locale}/student/statements/` },
    { page: `${t('statement:add_statement')}`, path: pathname },
  ]

  return (
    <Section
      title={t('statement:add_statement')}
      icon={<Statements />}
      rightElement={<BackButton link={`/${locale}/student/statements`} />}
      className="min-h-[calc(100vh_-_80px)]"
      breadcrubms={breadcrumbsItems}
    >
      <Form initialValues={initialValues} onSubmit={onSubmit} formRef={formRef} isConfirmable={!successfullySubmited}>
        <FormError />
        <FormContent
          templates={templates.data}
          currentTemplate={currentTemplate.data}
          setCurrentTemplateId={setCurrentTemplateId}
        />
      </Form>
    </Section>
  )
}

interface FormProps {
  readonly setCurrentTemplateId: (templateId: string) => unknown
  readonly templates: readonly StatementTemplate[]
  readonly currentTemplate: StatementTemplateView | null
}

function FormContent({ templates, currentTemplate, setCurrentTemplateId }: FormProps): React.ReactElement {
  const id = `label-${React.useId()}`
  const t = useTranslatable()
  const [fileInputId] = React.useState(() => `${id}-${Date.now()}`)
  const formik = useFormikContext<FormikValues>()
  const headers = useAuthenticatedHeaders()
  const facultyChangeTemplateId = '9'
  const [replaceTags, replaceTagsActions] = useMap<{ [key: string]: string }>({})
  const [tmpText, settmpText] = React.useState('')

  useApi({
    endpoint: Api.getStudentStatementsSemesters,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: formik.values.templateID,
        },
      }),
      [formik.values.templateID, headers]
    ),
    shouldFetch: currentTemplate != null && currentTemplate.isTypeMobility,
    suspense: false,
    onSuccess(data) {
      const html = `
        <select name="template[semesterId]" data-testid="template[semesterId]">
          <option value="">${t('statement:select_semester')}</option>
          ${data.map((e) => `<option value="${e.id}">${e.name}</option>`).join('')}
        </select>
      `
      replaceTagsActions.set('template[semesterId]', html)
    },
    onError() {
      toast.error(t('error:an_error_occurred'))
    },
  })
  useApi({
    endpoint: Api.getStudentStatementsFaculties,
    params: React.useMemo(
      () => ({
        headers,
      }),
      [headers]
    ),
    shouldFetch: formik.values.templateID === facultyChangeTemplateId,
    suspense: false,
    onSuccess(data) {
      const html = `
        <select name="template[facultyId]" data-testid="template[facultyId]">
          <option value="">${t('statement:select_faculty')}</option>
          ${data.map((e) => `<option value="${e.id}">${e.name}</option>`).join('')}
        </select>
      `
      replaceTagsActions.set('template[facultyId]', html)
    },
    onError() {
      toast.error(t('error:an_error_occurred'))
    },
  })

  let facultyId: string | undefined

  if (formik.values.templateID === facultyChangeTemplateId && formik.values.template.facultyId !== '') {
    facultyId = formik.values.template.facultyId
  }

  if (!facultyId && currentTemplate != null) {
    facultyId = currentTemplate.studentFacultyId.toString()
  }

  useApi({
    endpoint: Api.getStudentStatementsFacultiesPrograms,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: facultyId!,
        },
      }),
      [facultyId, headers]
    ),
    shouldFetch: currentTemplate != null && currentTemplate.studentFacultyId != null && currentTemplate.isTypeMobility,
    suspense: false,
    onSuccess(data) {
      const html = `
        <select name="template[programId]" data-testid="template[programId]">
          <option value="">${t('statement:select_programme')}</option>
          ${data.map((e) => `<option value="${e.id}">${e.name}</option>`).join('')}
        </select>
      `
      replaceTagsActions.set('template[programId]', html)
    },
    onError() {
      toast.error(t('error:an_error_occurred'))
    },
  })

  React.useEffect(() => {
    if (formik.values.templateID !== '') {
      void setCurrentTemplateId(formik.values.templateID)
    }
  }, [formik.values.templateID, setCurrentTemplateId])

  React.useEffect(() => {
    let templateText = ''

    if (currentTemplate != null) {
      templateText = currentTemplate.html

      for (const [key, value] of Object.entries(replaceTags)) {
        templateText = templateText.replace(
          new RegExp(`\\<select\\s*name="${key.replace(/([[\]])/g, '\\$1')}"[^>]*>\\s*<\\/select>`, 'g'),
          value
        )
      }
      settmpText(templateText)
    }
  }, [currentTemplate, replaceTags])

  return (
    <div className="mb-3 px-2">
      <div className="w-full">
        <SelectInput
          labelAnimation
          label={t('statement:choose_statement_template')}
          name="templateID"
          options={
            templates?.map((template: { id: string; title: string }) => ({
              value: template.id,
              label: template.title,
            })) ?? []
          }
        />
      </div>
      {currentTemplate != null && (
        <>
          <div className="my-6 rounded border-0">
            <div className="flex items-start rounded-card bg-card p-4 dark:text-white">
              <div className="mr-[16px] mt-1">
                <InfoIcon />
              </div>
              <div className="pr-0">
                <div
                  data-testid="templateHelpText"
                  className="overflow-auto rounded"
                  style={{ maxHeight: '300px' }}
                  dangerouslySetInnerHTML={{ __html: currentTemplate.helpText }}
                />
              </div>
            </div>
          </div>
          <div className="mb-4 text-right text-primaryTextColor">
            <p className="mb-2" data-testid="targetUserTitle">
              {currentTemplate.targetUserTitle}
            </p>
            <p className="mb-2" data-testid="targetUser">
              {currentTemplate.targetUser}
            </p>
            <p className="mb-2">
              {t('statement:education_unit')}:{' '}
              <span className="font-bold" data-testid="targetUserFaculty">
                {currentTemplate.userFaculty}
              </span>
            </p>
            <p className="mb-2">
              {t('program:programme')}:{' '}
              <span className="font-bold" data-testid="targetUserProgram">
                {currentTemplate.userProgram}
              </span>
            </p>
            <p className="mb-2">
              {t('common:academic_semester')}:{' '}
              <span className="font-bold" data-testid="targetUserSemester">
                {currentTemplate.userSemester}
              </span>
            </p>
            <p className="mb-2">
              <span data-testid="userDegree">{currentTemplate.userDegree}</span> :{' '}
              <span className="font-bold" data-testid="userFullName">
                {currentTemplate.userFullName}
              </span>
            </p>
          </div>
          <div className="statements-textarea mb-4">
            <div
              data-testid="templateText"
              dangerouslySetInnerHTML={{ __html: tmpText }}
              onInputCapture={(e) => {
                const target = e.target as HTMLElement

                if (target.tagName === 'SELECT') {
                  const target = e.target as HTMLSelectElement

                  void formik.setFieldValue(target.name, target.value)
                  const select = document.querySelector(`select[name="${target.name}"]`)
                  const options = select?.querySelectorAll('option')
                  options?.forEach((option) => {
                    if (option.value === target.value) {
                      option.setAttribute('selected', 'selected')
                    } else {
                      option.removeAttribute('selected')
                    }
                  })
                  replaceTagsActions.set(target.name, select?.outerHTML ?? '')
                }
              }}
            />
          </div>
          <div className="flex flex-col justify-center">
            <TextInput
              placeholder={t('person:enter_your_phone_number')}
              name="phone"
              type="text"
              required
              disabled={formik.isSubmitting}
              searchField
              data-testid="phone"
            />
            <TextInput
              type="text"
              placeholder={t('person:enter_your_address')}
              name="address"
              required
              searchField
              disabled={formik.isSubmitting}
              data-testid="address"
            />
            <div className="z-0 mb-2 mt-4">
              <FileInput
                key={fileInputId}
                multiple
                config={UploadConfigMap.studentFeedbacks}
                onChange={(files) => void formik.setFieldValue('files', files)}
                disabled={formik.isSubmitting}
              />
            </div>
            <div className="mb-3 flex w-full justify-end">
              <FormSubmit label={t('statement:sign_send')} data-testid="submitButton" />
            </div>
          </div>
        </>
      )}
    </div>
  )
}
