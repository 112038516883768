import * as FreeSolidSvgIcons from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FieldArray } from 'formik'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { toast } from 'react-toastify'
import * as Api from 'src/api'
import { DeleteIcon } from 'src/assets/icons/customIcons/upload-icons/Delete'
import { formatDate, formatDatelocale } from 'src/helpers/fns'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useTranslatable } from 'src/hooks/locale/utils'
import { Button } from 'src/tailwind/components/Button'
import DateInput from 'src/views/components/forms/formik/DateInput'
import { Form } from 'src/views/components/forms/formik/Form'
import { FormError } from 'src/views/components/forms/formik/FormError'
import { FormSubmit } from 'src/views/components/forms/formik/FormSubmit'
import SelectInput from 'src/views/components/forms/formik/SelectInput'
import TextInput from 'src/views/components/forms/formik/TextInput'
import TextareaInput from 'src/views/components/forms/formik/TextareaInput'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'
import Swal from 'sweetalert2'
import SwitchButton from './SwitchButton'

export default function StudentResumeAdditionalPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('resume:additional_info')} />
      <div className="flex w-full justify-end">
        <SwitchButton tab="additional" />
      </div>
      <SuspenseWrapper>
        <PageContent />
      </SuspenseWrapper>
    </>
  )
}
function PageContent(): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const t = useTranslatable()

  const { data, mutate } = useApi({
    endpoint: Api.getStudentResumeAdditional,
    params: React.useMemo(() => ({ headers }), [headers]),
  })

  const onSubmit = React.useCallback(
    async (values: Api.ResumeAdditional): Promise<void> => {
      await Api.postStudentResumeAdditional({
        headers,
        body: {
          info: values.info,
          publications: values.publications.map((publication) => ({
            name: publication.name,
            publisherName: publication.publisherName,
            type: publication.type,
            date: formatDate(publication.date)!,
            url: publication.url,
          })),
          fellowships: values.fellowships.map((fellowship) => ({
            type: fellowship.type,
            organisationName: fellowship.organisationName,
            startDate: formatDate(fellowship.startDate)!,
            endDate: formatDate(fellowship.endDate)!,
            cofinansist: fellowship.cofinansist,
          })),
          subjects: values.subjects.map((subject) => ({
            course: subject.course,
          })),
          videoLectures: values.videoLectures.map((video) => ({
            name: video.name,
            url: video.url,
          })),
        },
      })
      toast.success(t('person:profile_updated_successfully'))
      mutate()
    },
    [headers, mutate, t]
  )

  const handleDelete = React.useCallback(
    async (callback: () => void): Promise<void> => {
      const alert = await Swal.fire({
        title: t('common:do_you_really_want_to_delete'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0D6EFD',
        cancelButtonColor: '#6C757D',
        confirmButtonText: t('common:confirm'),
        cancelButtonText: t('common:cancel'),
      })

      if (alert.isConfirmed) {
        callback()
      }
    },
    [t]
  )

  return (
    <Form initialValues={data} onSubmit={onSubmit} classNames={{ form: 'w-full' }} enableReinitialize isConfirmable>
      <FormError />
      <SuspenseWrapper>
        <Publications handleDelete={handleDelete} />
      </SuspenseWrapper>
      <SuspenseWrapper>
        <Fellowships handleDelete={handleDelete} />
      </SuspenseWrapper>
      <FieldArray name="subjects">
        {({ remove, push, form }: any) => (
          <div className="my-4 flex w-full flex-col rounded-card bg-card">
            <h3 className="p-4 font-bold text-primaryTextColor">{t('course:learning_courses')}</h3>
            {form.values.subjects.map((item: string, index: number) => (
              <div
                key={index}
                className="my-2 flex w-full items-start justify-between border-t border-veryLightGrey p-4"
              >
                <div className="w-[90%]">
                  <TextInput
                    name={`subjects.${index}.course`}
                    placeholder={t('course:learning_course')}
                    type="text"
                    searchField
                    required
                  />
                </div>
                <button
                  title={t('common:delete')}
                  onClick={() => void handleDelete(() => remove(index))}
                  type="button"
                  className="mt-[6px] rounded-full p-2 hover:bg-hover-icon"
                >
                  <DeleteIcon />
                </button>
              </div>
            ))}
            <div className="w-full border-t border-veryLightGrey px-4">
              <Button
                variant="blue"
                type="button"
                className="my-4 w-full"
                onClick={() => push({ course: '' })}
                disabled={false}
              >
                <FontAwesomeIcon icon={FreeSolidSvgIcons.faPlus} /> {t('common:add_more')}
              </Button>
            </div>
          </div>
        )}
      </FieldArray>
      <FieldArray name="videoLectures">
        {({ remove, push, form }: any) => (
          <div className="my-4 flex w-full flex-col rounded-card bg-card">
            <h3 className="p-4 font-bold">{t('resume:video_lectures')}</h3>
            {form.values.videoLectures.map((item: string, index: number) => (
              <div key={index} className="w-full border-t border-veryLightGrey p-4">
                <div className="flex w-full justify-end">
                  <button
                    onClick={() => void handleDelete(() => remove(index))}
                    type="button"
                    className="rounded-full p-2 hover:bg-hover-icon"
                  >
                    <DeleteIcon />
                  </button>
                </div>
                <div className="flex w-full flex-wrap justify-between">
                  <div className="w-full md:w-[49%]">
                    <TextInput
                      name={`videoLectures.${index}.name`}
                      placeholder={t('common:title')}
                      type="text"
                      searchField
                      required
                    />
                  </div>
                  <div className="w-full md:w-[49%]">
                    <TextInput
                      name={`videoLectures.${index}.url`}
                      placeholder={t('common:link')}
                      type="text"
                      searchField
                      required
                    />
                  </div>
                </div>
              </div>
            ))}
            <div className="w-full border-t border-veryLightGrey px-4">
              <Button
                variant="blue"
                type="button"
                className="my-4 w-full"
                onClick={() => push({ name: '', url: '' })}
                disabled={false}
              >
                <FontAwesomeIcon icon={FreeSolidSvgIcons.faPlus} /> {t('common:add_more')}
              </Button>
            </div>
          </div>
        )}
      </FieldArray>
      <Info />
      <FormSubmit
        classNames={{
          root: 'flex justify-end',
        }}
      />
    </Form>
  )
}

function Info(): JSX.Element {
  const t = useTranslatable()

  return (
    <div className="my-4 flex w-full flex-col rounded-card bg-card">
      <h3 className="p-4 font-bold text-primaryTextColor">{t('resume:additional_info')}</h3>
      <div className="w-full border-t border-veryLightGrey p-4">
        <TextareaInput name="info" placeholder={t('common:information')} labelAnimation />
      </div>
    </div>
  )
}

const newFellowship = {
  type: '',
  organisationName: '',
  startDate: null,
  endDate: null,
  cofinansist: '',
}

interface FellowshipProps {
  handleDelete: (callback: () => void) => Promise<void>
}

const Fellowships = React.memo(function Fellowships({ handleDelete }: FellowshipProps) {
  const headers = useAuthenticatedHeaders()
  const t = useTranslatable()

  const { data: grantTypes, isValidating } = useApi({
    endpoint: Api.getStudentResumeGrantTypes,
    params: React.useMemo(() => ({ headers }), [headers]),
  })

  return (
    <FieldArray name="fellowships">
      {({ remove, push, form }: any) => (
        <div className="my-4 flex w-full flex-col rounded-card bg-card">
          <h3 className="p-4 font-bold text-primaryTextColor">{t('resume:grants')}</h3>
          {form.values.fellowships.map((fellowship: typeof newFellowship, index: number) => (
            <div key={index} className="my-2 w-full border-t border-veryLightGrey p-4">
              <div className="flex w-full justify-end">
                <button
                  onClick={() => void handleDelete(() => remove(index))}
                  type="button"
                  className="rounded-full p-2 hover:bg-hover-icon"
                >
                  <DeleteIcon />
                </button>
              </div>
              <div className="mb-3 flex w-full flex-wrap justify-between">
                <div className="mb-3 mr-0 w-full min-w-[100px] md:!mb-0 md:mr-2 md:w-[49%]">
                  <SelectInput
                    name={`fellowships.${index}.type`}
                    options={
                      grantTypes?.map((item: Api.ResumePublicationType) => ({
                        value: item.name ?? '',
                        label: item.name ?? '',
                      })) ?? []
                    }
                    label={t('resume:grant_type')}
                    isLoading={isValidating}
                    labelAnimation
                    required
                  />
                </div>
                <div className="w-full min-w-[100px] md:w-[49%]">
                  <TextInput
                    name={`fellowships.${index}.organisationName`}
                    placeholder={t('resume:issuer_organisation_name')}
                    type="text"
                    searchField
                    required
                  />
                </div>
              </div>
              <div className="flex w-full flex-wrap justify-between">
                <div className="mb-0 w-full min-w-[100px] md:w-[32%] xl:!mb-3">
                  <DateInput
                    labelAnimation
                    required
                    name={`fellowships.${index}.startDate`}
                    label={t('resume:gain_date')}
                    maxDate={formatDatelocale(fellowship.endDate)}
                  />
                </div>
                <div className="w-full min-w-[100px] md:w-[32%]">
                  <DateInput
                    labelAnimation
                    required
                    name={`fellowships.${index}.endDate`}
                    label={t('common:end_date')}
                    minDate={formatDatelocale(fellowship.startDate)}
                  />
                </div>
                <div className="w-full min-w-[100px] md:w-[32%]">
                  <TextInput
                    name={`fellowships.${index}.cofinansist`}
                    placeholder={t('resume:cofinansist')}
                    type="text"
                    searchField
                    required
                  />
                </div>
              </div>
            </div>
          ))}
          <div className="w-full border-t border-veryLightGrey px-4">
            <Button
              variant="blue"
              type="button"
              className="my-4 w-full"
              onClick={() => push(newFellowship)}
              disabled={false}
            >
              <FontAwesomeIcon icon={FreeSolidSvgIcons.faPlus} /> {t('common:add_more')}
            </Button>
          </div>
        </div>
      )}
    </FieldArray>
  )
})

const newPublication = {
  name: '',
  publisherName: '',
  type: '',
  date: null,
  url: '',
}

interface PublicationsProps {
  handleDelete: (callback: () => void) => Promise<void>
}

const Publications = React.memo(function Publications({ handleDelete }: PublicationsProps) {
  const headers = useAuthenticatedHeaders()
  const t = useTranslatable()

  const { data: publicationTypes, isValidating } = useApi({
    endpoint: Api.getStudentResumePublicationTypes,
    params: React.useMemo(() => ({ headers }), [headers]),
  })

  return (
    <FieldArray name="publications">
      {({ remove, push, form }: any) => (
        <div className="my-4 flex w-full flex-col rounded-card bg-card">
          <h3 className="p-4 font-bold text-primaryTextColor">{t('resume:publications')}</h3>
          {form.values.publications.map((_: string, index: number) => (
            <div key={index} className="my-2 w-full border-t border-veryLightGrey p-4">
              <div className="flex w-full justify-end">
                <button
                  onClick={() => void handleDelete(() => remove(index))}
                  type="button"
                  className="rounded-full p-2 hover:bg-hover-icon"
                >
                  <DeleteIcon />
                </button>
              </div>
              <div className="mb-3 flex w-full flex-wrap justify-between">
                <div className="mb-3 mr-0 w-full min-w-[100px] md:!mb-0 md:mr-2 md:w-[49%]">
                  <TextInput
                    name={`publications.${index}.name`}
                    placeholder={t('resume:publication_name')}
                    type="text"
                    searchField
                    required
                  />
                </div>
                <div className="w-full min-w-[100px] md:w-[49%]">
                  <SelectInput
                    name={`publications.${index}.type`}
                    options={
                      publicationTypes?.map((item: Api.ResumePublicationType) => ({
                        value: item.name ?? '',
                        label: item.name ?? '',
                      })) ?? []
                    }
                    label={t('resume:publication_type')}
                    isLoading={isValidating}
                    labelAnimation
                    required
                  />
                </div>
              </div>
              <div className="mb-3 flex w-full flex-wrap justify-between">
                <div className="mb-3 mr-0 w-full min-w-[100px] md:!mb-0 md:mr-2 md:w-[49%]">
                  <TextInput
                    name={`publications.${index}.publisherName`}
                    placeholder={t('resume:publisher_name')}
                    type="text"
                    searchField
                    required
                  />
                </div>
                <div className="w-full min-w-[100px] md:w-[49%]">
                  <DateInput
                    labelAnimation
                    required
                    name={`publications.${index}.date`}
                    label={t('resume:publish_date')}
                    disabled={false}
                  />
                </div>
              </div>
              <div className="w-full">
                <TextInput
                  name={`publications.${index}.url`}
                  placeholder={t('common:link')}
                  type="text"
                  searchField
                  required
                />
              </div>
            </div>
          ))}
          <div className="w-full border-t border-veryLightGrey px-4">
            <Button
              variant="blue"
              type="button"
              className="my-4 w-full"
              onClick={() => push(newPublication)}
              disabled={false}
            >
              <FontAwesomeIcon icon={FreeSolidSvgIcons.faPlus} /> {t('common:add_more')}
            </Button>
          </div>
        </div>
      )}
    </FieldArray>
  )
})
