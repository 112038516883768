import LogoIconEng from 'src/assets/icons/menu/argus_logo_eng.svg'
import LogoIconGeo from 'src/assets/icons/menu/argus_logo_geo.svg'
import { useLocale } from 'src/hooks/locale/locale'

interface Props {
  readonly className?: string
}

export default function Argus({ className = '' }: Props): JSX.Element {
  const locale = useLocale()

  const logo = locale === 'en' ? LogoIconEng : LogoIconGeo

  return <img className={`ml-[2px] duration-[800ms] ${className}`} src={logo} alt="argus-logo" />
}
